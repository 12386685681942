import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/Dashboard.vue")
        },
        {
          path: "/document",
          name: "document",
          redirect: '/document/list',
          component: () =>
            import("@/views/pages/document/Document.vue"),
          children: [
            {
              path: "history",
              name: "document-history",
              component: () => import("@/views/pages/document/History.vue")
            },
            {
              path: "history-drafts",
              name: "document-history-drafts",
              component: () => import("@/views/pages/document/History.vue")
            },
            {
              path: "list",
              name: "document-list",
              component: () => import("@/views/pages/document/List.vue")
            },
            {
              path: "edit/:id?",
              name: "document-edit",
              component: () => import("@/views/pages/document/Edit.vue")
            },
            {
              path: "edit/:did/template/:id?",
              name: "document-edit-template",
              component: () => import("@/views/pages/document/EditTemplate.vue")
            },
            {
              path: "form/:id",
              name: "document-form",
              component: () => import("@/views/pages/document/Form.vue")
            },
            {
              path: "form/:id/submission/:sid/view",
              name: "document-submission-view",
              component: () => import("@/views/pages/document/Form.vue")
            },
            {
              path: "form/:id/draft/:sid/view",
              name: "document-draft-view",
              component: () => import("@/views/pages/document/Form.vue")
            },
            {
              path: "form/:id/submissioncopy/:csid/view",
              name: "document-submission-copy",
              component: () => import("@/views/pages/document/Form.vue")
            },
            {
              path: "form/:id/submissionrecopy/:csid/view",
              name: "document-submission-recopy",
              component: () => import("@/views/pages/document/Form.vue")
            }
          ]
        },
        {
          path: "/ext-crm",
          name: "ext-crm",
          component: () =>
                import("@/views/pages/ext-crm/Index.vue"),
          children: [
            {
              path: "edit/:id?",
              name: "ext-crm-edit",
              component: () => import("@/views/pages/ext-crm/Edit.vue")
            },
            {
              path: "list",
              name: "ext-crm-list",
              component: () => import("@/views/pages/ext-crm/List.vue")
            },
          ]
        },
        {
          path: "/setting",
          name: "document",
          component: () =>
            import("@/views/pages/setting/Setting.vue"),
          children: [
            {
              path: "user/editc",
              name: "setting-user-edit-current",
              component: () => import("@/views/pages/setting/user/EditCurrent.vue")
            },
            {
              path: "user/edit/:id?",
              name: "setting-user-edit",
              component: () => import("@/views/pages/setting/user/Edit.vue")
            },
            {
              path: "user/list",
              name: "setting-user-list",
              component: () => import("@/views/pages/setting/user/List.vue")
            },
            {
              path: "user/change-password",
              name: "force-change-password",
              component: () => import("@/views/pages/setting/user/ForceChangePassword.vue")
            },
          ]
        },
        {
          path: "/setting-organization",
          name: "document",
          component: () =>
            import("@/views/pages/setting-organization/Setting.vue"),
          children: [
            {
              path: "user/edit/:id?",
              name: "setting-organization-user-edit",
              component: () => import("@/views/pages/setting-organization/user/Edit.vue")
            },
            {
              path: "user/list",
              name: "setting-organization-user-list",
              component: () => import("@/views/pages/setting-organization/user/List.vue")
            },
            {
              path: "group/edit/:id?",
              name: "setting-organization-group-edit",
              component: () => import("@/views/pages/setting-organization/group/Edit.vue")
            },
            {
              path: "group/list",
              name: "setting-organization-group-list",
              component: () => import("@/views/pages/setting-organization/group/List.vue")
            },
          ]
        },
        {
          path: "/setting-admin",
          name: "document",
          component: () =>
            import("@/views/pages/setting-admin/Setting.vue"),
          children: [
            {
              path: "main/edit/:id?",
              name: "setting-admin-main-edit",
              component: () => import("@/views/pages/setting-admin/main/Edit.vue")
            },
            {
              path: "main/list",
              name: "setting-admin-main-list",
              component: () => import("@/views/pages/setting-admin/main/List.vue")
            },
          ]
        }
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/views/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/views/pages/error/Error-1.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/pages/auth/Login")
        },
        {
          name: "loginrecover",
          path: "/loginrecover",
          component: () => import("@/views/pages/auth/Recover")
        },
        {
          name: "loginreset",
          path: "/loginreset/:email",
          component: () => import("@/views/pages/auth/Reset")
        }
        // {
        //   name: "register",
        //   path: "/register",
        //   component: () => import("@/views/pages/auth/Register")
        // }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue")
    }
  ]
});
